import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import Prev from '../../images/Button-Prev-chevrons.svg'
import Next from '../../images/Button-Next-chevrons.svg'

const StoryWrapper = styled.div`
  .point {
    margin-top: -1px;
  }
  .year {
    z-index: 1;
    top: 44%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .slick-disabled {
    img {
      opacity: 0.25;
    }
  }
  .slick-prev,
  .slick-next {
    top: 28%;
    z-index: 99;
  }
  .slick-prev {
    left: -30px !important;
  }
  .slick-next {
    right: -30px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    content: '';
  }
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #38c3e0;
  }
  @media (max-width: 640px) {
    .slick-prev,
    .slick-next {
      top: 26%;
    }
    .slick-prev {
      left: -25px !important;
    }
    .slick-next {
      right: -25px !important;
    }
  }
`
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={Next} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={Prev} />
    </div>
  )
}
const Story = ({ story, items }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <StoryWrapper tw="container pt-8 pb-8 text-center md:pb-14">
      <div>
        <h2 tw="text-navy">{story.headline.text}</h2>
      </div>
      <Slider {...settings} tw="mt-16">
        {items.map((item, key) => {
          return (
            <div key={key}>
              <div tw="grid grid-cols-3 items-center">
                <div tw="pl-4 md:pl-0">
                  <hr
                    tw="flex-grow bg-turquoise border-turquoise"
                    style={{ opacity: key === 0 ? '0' : '1' }}
                    className="line"
                  />
                </div>
                <div tw="relative">
                  <Img fluid={item.icon.fluid} tw="w-full" />
                  <p
                    tw="absolute text-teal text-sm font-bold tracking-widest"
                    className="year">
                    {item.year}
                  </p>
                </div>
                <div tw="flex items-center pr-4 md:pr-0">
                  <hr
                    tw="flex-grow border-turquoise"
                    style={{ opacity: key == items.length - 1 ? '0' : '1' }}
                    className="line"
                  />

                  <span
                    tw="text-turquoise text-xl"
                    className="point"
                    style={{ opacity: key == items.length - 1 ? '0' : '1' }}>
                    •
                  </span>
                </div>
              </div>
              <div tw="w-60 m-auto mt-8 text-ocean text-sm">
                <span style={{ lineHeight: '20px' }}>{item.story}</span>
              </div>
            </div>
          )
        })}
      </Slider>
    </StoryWrapper>
  )
}
export default Story
