import React from 'react'
import Helmet from 'react-helmet'
import i18n from '../../../config/i18n'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, locale }) => {
  const { defaultTitle, defaultDescription, siteLanguage } = i18n[locale]

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
      </Helmet>
    </>
  )
}

export default SEO
