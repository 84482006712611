import React from 'react'
import { styled } from 'twin.macro'

const AboutIntroWrapper = styled.div``

const AboutIntro = ({ intro }) => {
  return (
    <AboutIntroWrapper tw="px-0 pt-12 sm:px-10 md:px-16 md:pt-28 pb-28 text-center md:pb-28">
      <div tw="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16">
        <div tw="flex items-center md:text-left">
          <h2 tw="text-navy">{intro.headline.text}</h2>
        </div>
        <div tw="md:col-span-2 flex items-center md:text-left">
          <p tw="text-xl text-ocean">
            {intro.intro_description.text}
          </p>
        </div>
      </div>
    </AboutIntroWrapper>
  )
}
export default AboutIntro
