import React from 'react'
import { graphql } from 'gatsby'
import { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import WatchElementAnimate from '../components/watch-element-animate'
import background from '../images/Hero_Background_Image_03.svg'
import SEO from '../components/SEO/SEO'
import AboutIntro from '../components/about/about-intro'
import WhoWeAre from '../components/about/who-we-are'
import Story from '../components/about/story'
import Values from '../components/about/values'
import Advisors from '../components/about/advisors'
import Where from '../components/about/where'
import LatestNews from '../components/about/latest-news'
import { withPreview } from 'gatsby-source-prismic'
import 'react-modal-video/css/modal-video.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// styles
const HeroWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  @media (max-width: 769px) {
    padding-top: 138px;
  }
`
// markup
const AboutPage = ({
  data: { about, resourcesList },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <SEO
        title={about.data.meta_title}
        desc={about.data.meta_description}
        locale={locale}
      />
      {about.data.body.map((b, index) => {
        switch (b.slice_type) {
          case 'intro':
            return (
              <HeroWrapper>
                <div tw="container">
                  <div tw="text-center relative">
                    <AboutIntro intro={b.primary} />
                  </div>
                </div>
              </HeroWrapper>
            )
          case 'who_we_are':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <WhoWeAre who={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'our_story':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Story story={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'latest_news':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <LatestNews
                    news={b.primary}
                    resourcesList={resourcesList.nodes}
                    i18n={i18n}
                  />
                </div>
              </WatchElementAnimate>
            )
          case 'our_values':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Values values={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'our_advisors':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Advisors advisors={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'where_we_are':
            return (
              <WatchElementAnimate
                className={`.about__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`about__animated__${index}`}>
                  <Where where={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          default:
            return null
        }
      })}
    </main>
  )
}

export default withPreview(AboutPage)

export const pageQuery = graphql`
  query AboutQuery($locale: String!) {
    about: prismicAbout(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        body {
          ... on PrismicAboutBodyIntro {
            slice_type
            primary {
              headline {
                text
              }
              intro_description {
                text
              }
            }
          }
          ... on PrismicAboutBodyWhoWeAre {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              linkedin {
                url
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              name
              role
              email
            }
          }
          ... on PrismicAboutBodyOurStory {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              year
              story
            }
          }
          ... on PrismicAboutBodyLatestNews {
            slice_type
            primary {
              headline {
                text
              }
            }
          }
          ... on PrismicAboutBodyOurValues {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              value_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              value
              value_description
            }
          }
          ... on PrismicAboutBodyOurAdvisors {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              email
              name
              role
              linkedin {
                url
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicAboutBodyWhereWeAre {
            slice_type
            primary {
              headline {
                text
              }
              address
              email
              phone
              subheadline {
                text
              }
              map_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              geopoint {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
    resourcesList: allPrismicResource(
      sort: { fields: first_publication_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      nodes {
        data {
          meta_title
          category
          meta_description
          content {
            html
          }
          image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          link {
            url
          }
        }
        first_publication_date
        uid
      }
    }
  }
`
