import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import LinkedIn from '../../images/LinkedIn.svg'
import Email from '../../images/mail-solid.svg'
import Slider from 'react-slick'

const AdvisorsWrapper = styled.div`
  .popular-slider {
    margin: 30px -25px 0 -25px;
    .slick-item {
      padding: 0 10px;
    }
  }
`

const Advisors = ({ advisors, items }) => {
  const settings = {
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb popular-dots',
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <AdvisorsWrapper tw="container pt-8 pb-8 text-center md:pb-14">
      <div>
        <h2 tw="text-navy">{advisors.headline.text}</h2>
        <p tw="text-lg text-ocean max-w-screen-sm m-auto mt-5">
          {advisors.subheadline.text}
        </p>
      </div>
      <div tw="hidden md:grid grid-cols-2 gap-8 mt-16 text-left">
        {items.map((item, key) => {
          return (
            <div key={key} tw="flex items-center justify-self-center">
              <div tw="w-64	flex-none">
                <Img fluid={item.image.fluid} tw="rounded-l-lg" />
              </div>
              <div tw="pl-14">
                <h4 tw="text-navy">{item.name}</h4>
                <p tw="text-ocean mb-5">{item.role}</p>
                <div tw="flex gap-2">
                  <div tw="border-t flex pt-5 gap-2 border-ocean border-opacity-20">
                    <Link to={item.linkedin.url} target="_blank">
                      <img tw="w-4" src={LinkedIn} />
                    </Link>
                    <a href="mailto:info@quantics.io">
                      <img tw="w-4" src={Email} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <Slider {...settings} tw="md:hidden mt-8" className="popular-slider">
        {items.map((item, key) => {
          return (
            <div key={key} tw="flex flex-col" className="slick-item">
              <Img fluid={item.image.fluid} />

              <div tw="flex-grow p-5 md:pt-0 md:pl-14 text-left">
                <h4 tw="text-navy">{item.name}</h4>
                <p tw="text-ocean mb-5">{item.role}</p>
                <div tw="flex gap-2">
                  <div tw="border-t flex pt-5 gap-2 border-ocean border-opacity-20">
                    <Link to={item.linkedin.url} target="_blank">
                      <img tw="w-4" src={LinkedIn} />
                    </Link>
                    <a href="mailto:info@quantics.io">
                      <img tw="w-4" src={Email} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </AdvisorsWrapper>
  )
}
export default Advisors
