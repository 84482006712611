import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Light from '../../images/Lightbulb.svg'
import background from '../../images/Hexagons_Grid_background.svg'
import backgroundMobile from '../../images/Hexagons-Grid-background-Mobile.svg'
const ValuesWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  .item-1 {
    margin-top: 36px;
  }
  @media (max-width: 614px) {
    background-image: url(${backgroundMobile});
    .item-1 {
      margin-top: 0;
    }
  }
`

const Values = ({ values, items }) => {
  return (
    <ValuesWrapper tw="pt-8 pb-8 text-center md:pb-14">
      <div tw="container">
        <h2 tw="text-navy">{values.headline.text}</h2>
        <div tw="grid md:grid-cols-3 gap-4 md:gap-16 justify-items-center">
          {items.map((item, key) => {
            return (
              <div
                key={key}
                tw="text-center w-64 pt-10 pb-0 md:pb-10 bg-white"
                className={'item-' + key}>
                <hr tw="w-3/6 m-auto mb-10 text-turquoise border-2" />
                <Img fluid={item.value_icon.fluid} tw="w-8 m-auto" />
                <h4 tw="mt-5 text-navy">{item.value}</h4>
                <p tw="w-56 m-auto mt-3 text-ocean">{item.value_description}</p>
              </div>
            )
          })}
        </div>
      </div>
    </ValuesWrapper>
  )
}
export default Values
