import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import { SmallSolidTealButton } from '../../utils/styles'
import { Link } from 'gatsby'
import ModalVideo from 'react-modal-video'
import Slider from 'react-slick'
import Prev from '../../images/Button-Prev-chevrons.svg'
import Next from '../../images/Button-Next-chevrons.svg'
import i18n from '../../../config/i18n'
const LatestNewsWrapper = styled.div`
  .popular-slider {
    margin: 30px -25px 0 -25px;
    @media (max-width: 640px) {
      .slick-item {
        padding: 0 5px;
      }
    }
  }
  .popular-slider-desktop {
    display: grid !important;
  }
  .slick-disabled {
    img {
      opacity: 0.25;
    }
  }
  .slick-prev {
    right: 60px;
    left: auto;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev,
  .slick-next {
    font-size: 22px;
    top: -23px;
  }
  .slick-prev:before,
  .slick-next:before {
    content: '';
  }
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
  }
  @media (max-width: 640px) {
    .slick-prev,
    .slick-next {
      top: -45px;
    }
  }
`
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={Next} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={Prev} />
    </div>
  )
}
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('.')
}
const LatestNews = ({ news, resourcesList, i18n }) => {
  const [isOpen, setOpen] = useState(false)
  const [videoItem, setVideoItem] = useState(null)

  const desktopSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          centerMode: true,
          centerPadding: '5%',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const resources = resourcesList.filter(
    (item) => item.data.category === 'News'
  )
  return (
    <LatestNewsWrapper tw="container pt-20 pb-8 md:pt-28 md:pb-14">
      <h2 tw="text-navy">{news.headline.text}</h2>
      <Slider {...desktopSettings} className="popular-slider">
        {resources.map((item, key) => {
          return (
            <div
              tw="grid md:grid-cols-12 mt-8 rounded-lg px-2 md:px-0"
              key={key}
              className="popular-slider-desktop slick-item">
              <div tw="relative md:col-span-5">
                <Link to={item.data.link.url} target="_blank">
                  <Img
                    fluid={item.data.image.fluid}
                    tw="h-60 md:h-72 rounded-t-lg md:rounded-l-lg md:rounded-tr-none"></Img>
                </Link>
                <div tw="absolute top-7 left-7 py-2 px-5 bg-turquoise font-bold text-sm uppercase tracking-widest text-white rounded">
                  {formatDate(item.first_publication_date)}
                </div>
              </div>
              <div tw="h-80 md:h-auto md:col-span-7 bg-air py-8 md:py-0 text-center md:text-left px-6 md:px-12 md:flex-grow md:flex md:gap-16 items-center rounded-b-lg md:rounded-r-lg md:rounded-bl-none">
                <div tw="flex-grow">
                  <h4 tw="text-navy">{item.data.meta_title}</h4>
                  <p tw="text-ocean mt-4">{item.data.meta_description}</p>
                </div>
                <div tw="w-36 flex-none m-auto md:m-0">
                  <Link to={item.data.link.url} target="_blank">
                    <SmallSolidTealButton tw="mt-5">
                      {i18n.about_us.latest_news.button}
                    </SmallSolidTealButton>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </LatestNewsWrapper>
  )
}
export default LatestNews
